import request from '@/utils/request'

// 用户操作记录列表
export function addlog(cid = null, data) {
  return request({
    method: 'post',
    headers: { "Device-Id": cid },
    url: '/api/oper/log/add',
    data,
  })
}

// 获取微信相关配置
export function getJsapiSignature(scanUrl) {
  return request({
    method: 'GET',
    url: `/api/jsapiSignature/v1.0.0?scanUrl=` + scanUrl,
  })
}
