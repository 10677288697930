import request from '@/utils/request'

// 用户资产查询
export function getAssets(params = {}) {
  return request({
    method: 'get',
    url: '/api/user/getAssets/v1.0.0',
    params
  })
}


// 填写邀请码
export function subInviteCode(params = {}) {
  return request({
    method: 'get',
    url: '/api/inviteCode/v1.0.0',
    params
  })
}

// 填写兑换码
export function subRedeemCode(data){
  return request({
    method:'post',
    url:'/api/storeRedemptionUserHistory/redemption',
    data
  })
}
// 盲盒推荐列表
export function bindBoxRecommended(params){
    return request({
      method:'get',
      url:'/api/h5/agentStore/page/v2.0.0',
      params
    })
}