import request from '@/utils/request'

// 根据类型获取字典
export function listByType(type) {
  return request({
    method: 'GET',
    url: '/api/dictType/type/v1.0.0',
    params: { dictType: type },
  })
}
