import Vue from 'vue';
import Vconsole from 'vconsole'
if (process.env.NODE_ENV == 'development'||process.env.NODE_ENV == 'test-production') {
  new Vconsole()
}
import {
  Button,
  Cell,
  CellGroup,
  Collapse,
  CollapseItem,
  Field,
  Icon,
  NavBar,
  Toast,
  Dialog,
} from 'vant';
import { Image as VanImage } from 'vant';
import App from './App.vue';
import router from './router';
import store from './store';
import api from '@/api';
import '@/mixins';
import '@/assets/css/font.css'
import '@/assets/css/publicCss.css'
import VueClipboard from 'vue-clipboard2'
import VueLuckyCanvas from '@lucky-canvas/vue'
import filters from "./filters/index"
import VueBridgeWebview from './utils/bridge.js'
import { getToken } from './utils/getToken'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import Carousel3d from 'vue-carousel-3d';
import VueI18n from 'vue-i18n'
import WXJSSDDK from "weixin-js-sdk";
Vue.use(VueI18n)
import { i18n, vantLocales } from './utils/lang';
vantLocales(i18n.locale);
Vue.use(Carousel3d);
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
// 微信授权登录弹窗
import wxLoginPopup from "@/components/wxLoginPopup/wxLoginPopup.vue";
Vue.component('wxLoginPopup', wxLoginPopup)
import "swiper/css/swiper.css"
import '@/utils/check-image.js'
// 添加全局filter
Object.keys(filters).map(v => {
  Vue.filter(v, filters[v])
})
import './permission'
Vue.use(VueLuckyCanvas)
Vue.use(VueBridgeWebview)
// set default config,default handleDelayTime = 0 * 1000,silent = false
let WVJBIframe = document.createElement('iframe')
WVJBIframe.style.display = 'none'
WVJBIframe.src = 'https://__bridge_loaded__'
document.documentElement.appendChild(WVJBIframe)
setTimeout(() => {
  document.documentElement.removeChild(WVJBIframe)
}, 0)

Vue.use(Button);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Field);
Vue.use(Icon);
Vue.use(NavBar);
Vue.use(VueClipboard);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(VanImage);

Vue.prototype.$api = api
Vue.prototype.$wx = WXJSSDDK;
Vue.config.productionTip = false;
Vue.prototype.$Toast = Toast
Vue.prototype.getToken = getToken//全局挂载,获取用户token
Vue.prototype.FingerprintJS = FingerprintJS;
Vue.prototype.$AdyenCheckout = AdyenCheckout;
Vue.config.ignoredElements = ['wx-open-launch-weapp']
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
