import request from '@/utils/request'

// 新增意见与反馈
export function create(data) {
  return request({
    method: 'POST',
    url: '/api/userAdvise/saveAdvise/v1.0.0',
    data,
  })
}

//查询详情
export function adviseDetail(id) {
  return request({
    method: 'GET',
    url: `/api/userAdvise/userAdviseDetail/${id}`,
  })
}


//用户已读
export function userReadFeedback(id) {
  return request({
    method: 'POST',
    url: `/api/userAdvise/userReadFeedback/v1.0.0`,
    data: {
      adviseId: id
    }
  })
}

//查询用户反馈已处理未读条数
export function unreadRepliedCount() {
  return request({
    method: 'GET',
    url: `/api/userAdvise/unreadRepliedCount/v1.0.0`,
  })
}




