import router from './router'
import store from './store'
import { Toast } from 'vant'
import { getToken, setToken } from '@/utils/localStorage'
// 微信授权登录弹窗
import wxLoginPopup from "@/components/wxLoginPopup/wxLoginPopup.vue";

// 定义不需要登录能访问的路由名称数组
const whiteList = store.state.whiteList;
router.beforeEach((to, from, next) => {
  let token = getToken();
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.scrollTo(0, 0);
  store.commit('updateLastTimePath', from.fullPath)
  if (getToken()) {
    /* has token*/
    next()
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      next() // 否则直接进入
    } else {
      // 将重定向到登录页前的路由信息存储起来
      store.commit('SET_TOPATH', to.fullPath)
      // wxLoginPopup.methods.openLoginFn();
      Toast('请先登录')
      // 不在白名单内，全部重定向到登录页
      // next('/');
    }
  }
})
