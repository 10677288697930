import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  // **************************** 公众号 ****************************
  {
    path: '/officialAccountsHome',
    name: 'officialAccountsHome', //首页
    meta: { keepAlive: false },
    component: () => import('../views/officialAccounts/home.vue'),
  },
  {
    path: '/bindingLogin',
    name: 'bindingLogin', //员工--手机号绑定登录
    meta: { keepAlive: false },
    component: () => import('../views/officialAccounts/bindingLogin.vue'),
  },
  {
    path: '/storeSelection',
    name: 'storeSelection', //员工--门店选择/二维码页
    meta: { keepAlive: false },
    component: () => import('../views/officialAccounts/storeSelection.vue'),
  },
  {
    path: '/bindingOrderDetails',
    name: 'bindingOrderDetails', //员工--订单详情
    meta: { keepAlive: false },
    component: () => import('../views/officialAccounts/bindingOrderDetails.vue'),
  },
  {
    path: '/officialAccountsLogin',
    name: 'officialAccountsLogin', //公众号登录页
    meta: { keepAlive: false },
    component: () => import('../views/officialAccounts/login.vue'),
  },
  {
    path: '/writeOff',
    name: 'writeOff', //店铺核销
    meta: { keepAlive: false },
    component: () => import('../views/officialAccounts/writeOff.vue'),
  },
  {
    path: '/writeOffOrder',
    name: 'writeOffOrder', //核销订单
    meta: { keepAlive: false },
    component: () => import('../views/officialAccounts/writeOffOrder.vue'),
  },
  {
    path: '/summaryDetails',
    name: 'summaryDetails', // 汇总明细
    component: () => import('../views/officialAccounts/summaryDetails.vue'),
  },
  {
    path: '/writeOffOrderDetails',
    name: 'writeOffOrderDetails', // 核销-订单详情
    component: () => import('../views/officialAccounts/writeOffOrderDetails.vue'),
  },

  // **************************** 公众号 ****************************

  // **************************** 时间实验室-宝盒时间 ****************************
  {
    path: '/',
    name: 'home', //首页
    meta: { keepAlive: false },
    component: () => import('../views/home.vue'),
  },
  {
    path: '/homePoster',
    name: 'homePoster', //店铺分享海报
    meta: { keepAlive: false },
    component: () => import('../views/homePageSwitch/homePoster.vue'),
  },
  {
    path: '/howPlay',
    name: 'howPlay', //玩法介绍
    meta: { keepAlive: false },
    component: () => import('../views/blindBox/howPlay.vue'),
  },
  {
    path: '/shopDetails',
    name: 'shopDetails', //店铺详情
    meta: { keepAlive: false },
    component: () => import('../views/shop/shopDetails.vue'),
  },
  {
    path: '/wechatCode',
    name: 'wechatCode', //微信二维码
    meta: { keepAlive: false },
    component: () => import('../views/shop/wechatCode.vue'),
  },
  {
    path: '/storageBox', //收纳盒
    name: 'storageBox',
    meta: { keepAlive: false },
    component: () => import('../views/storageBox/storageBox.vue'),
  },
  {
    path: '/pickedUp',
    name: 'pickedUp', //赠送宝盒-待领取/已领取
    meta: { keepAlive: false },
    component: () => import('../views/blindBoxGift/pickedUp.vue'),
  },
  {
    path: '/openGifts',
    name: 'openGifts', //赠送宝盒-待领取/已领取
    meta: { keepAlive: false },
    component: () => import('../views/blindBoxGift/openGifts.vue'),
  },
  {
    path: '/blindBoxDetail', //盲盒详情
    name: 'blindBoxDetail',
    meta: { keepAlive: false },
    component: () => import('../views/blindBox/blindBoxDetail.vue'),
  },
  {
    path: '/blindBox/confirmOrder/:num?/:blindBoxId?/:btnType?/:isGiveAway?/:orderNo?/:orderId?',
    name: 'bConfirmOrder', // 盲盒确认订单页
    meta: { keepAlive: false },
    component: () => import('../views/blindBox/confirmOrder.vue'),
  },
  {
    path: '/lotteryPage/:blindBoxId?/:openType?/:orderNo?',
    name: 'lotteryPage', // 开奖页面
    component: () => import('../views/blindBox/lotteryPage.vue'),
  },
  // {
  //   path: '/lotteryPage/:blindBoxId?/:openType?/:orderNo?',
  //   name: 'lotteryPage', // 开奖页面
  //   component: () => import('../views/lotteryPage.vue'),
  // },
  {
    path: '/comment',
    name: 'comment', //我要評論
    meta: { keepAlive: false },
    component: () => import('../views/CommentCreate.vue'),
  },
  {
    path: '/goodsDetails', //商品详情页
    name: 'goodsDetails',
    meta: { keepAlive: false },
    component: () => import('../views/goodsDetails.vue'),
  },
  {
    path: '/accountSetting', //设置
    name: 'accountSetting',
    meta: { keepAlive: false },
    component: () => import('../views/account/accountSetting.vue')
  },
  {
    path: '/accountManagement', //账号管理
    name: 'accountManagement',
    meta: { keepAlive: false },
    component: () => import('../views/account/accountManagement.vue')
  },
  {
    path: '/changePassword', //修改密码
    name: 'changePassword',
    meta: { keepAlive: false },
    component: () => import('../views/account/changePassword.vue')
  },
  {
    path: '/sendVerificationCode', //发送验证码
    name: 'sendVerificationCode',
    meta: { keepAlive: false },
    component: () => import('../views/account/sendVerificationCode.vue')
  },
  {
    path: '/changeNickname', //修改昵称
    name: 'changeNickname',
    meta: { keepAlive: false },
    component: () => import('../views/account/changeNickname.vue')
  },
  {
    path: '/selectAddress', //选择地址
    name: 'selectAddress',
    meta: { keepAlive: false },
    component: () => import('../views/address/selectAddress.vue'),
  },
  {
    path: '/addAddress', //添加地址
    name: 'addAddress',
    meta: { keepAlive: false },
    component: () => import('../views/address/addAddress.vue'),
  },
  {
    path: '/userPage', //我的
    name: 'userPage',
    meta: { keepAlive: false },
    component: () => import('../views/user/userPage.vue'),
  },
  {
    path: '/myOrder', //我的订单
    name: 'myOrder',
    meta: { keepAlive: false },
    component: () => import('../views/myOrder.vue'),
  },
  {
    path: '/unopenedBindBox', //未开盲盒
    name: 'unopenedBindBox',
    meta: { keepAlive: false },
    component: () => import('../views/unopenedBindBox.vue'),
  },
  {
    path: '/giftOrder', //礼物订单
    name: 'giftOrder',
    meta: { keepAlive: false },
    component: () => import('../views/order/giftOrder.vue'),
  },
  {
    path: '/orderDetails', //订单详情页
    name: 'orderDetails',
    component: () => import('../views/orderDetails.vue'),
  },
  // **************************** 时间实验室-宝盒时间 ****************************

  {
    path: '/login',
    name: 'login', //登录页
    meta: { keepAlive: false },
    component: () => import('../views/login/login.vue'),
  },
  {
    path: '/resetPwd',
    name: 'resetPwd', //忘记密码页
    meta: { keepAlive: false },
    component: () => import('../views/login/resetPwd.vue'),
  },
  {
    path: '/homePage',
    name: 'homePage', //首页--泰版
    meta: { keepAlive: false },
    component: () => import('../views/homePage.vue'),
  },
  {
    path: '/sellOffConfirm', //变卖确认页
    name: 'sellOffConfirm',
    meta: { keepAlive: false },
    component: () => import('../views/storageBox/sellOffConfirm.vue'),
  },
  {
    path: '/pickUpPage', //取货确认页
    name: 'pickUpPage',
    meta: { keepAlive: false },
    component: () => import('../views/storageBox/pickUpPage.vue'),
  },
  {
    path: '/sellOffSucceed', //变卖成功页
    name: 'sellOffSucceed',
    meta: { keepAlive: false },
    component: () => import('../views/storageBox/sellOffSucceed.vue'),
  },
  {
    path: '/rechargePage', //充值页面
    name: 'rechargePage',
    meta: { keepAlive: false },
    component: () => import('../views/rechargePage.vue'),
  },
  {
    path: '/balanceDetails',
    name: 'balanceDetails', // 幸运币收支明细
    meta: { keepAlive: false },
    component: () => import('@/views/balanceDetails.vue'),
  },

  {
    path: '/myCoupon',
    name: 'myCoupon', // 我的优惠券
    meta: { keepAlive: false },
    component: () => import('@/views/coupon/myCoupon.vue'),
  },
  {
    path: '/FunctionCard',
    name: 'FunctionCard', //功能卡
    meta: { keepAlive: false },
    component: () => import('../views/functionCard/FunctionCard.vue'),
  },

  {
    path: '/checkout/cardPay', // adyen信用卡支付页面
    name: 'bCardPay',
    component: () => import('../views/checkout/cardPay.vue'),
  },
  {
    path: '/checkout/applePay', // adyen applePay支付页面
    name: 'bApplePay',
    component: () => import('../views/checkout/applePay.vue'),
  },
  {
    path: '/checkout/checkOut', //支付页面
    name: 'bCheckOut',
    component: () => import('../views/checkout/checkOut.vue'),
  },
  {
    path: '/blindBox/shareBoxPoster', //盲盒分享海报
    name: 'shareBoxPoster',
    meta: { keepAlive: false },
    component: () => import('../views/blindBox/shareBoxPoster.vue'),
  },
  {
    path: '/Question',
    name: 'commonQuestion', //问题列表
    meta: { keepAlive: false },
    component: () => import('../views/CommonQuestion.vue'),
  },
  {
    path: '/Complaint',
    name: 'complaint', //投訴建議
    meta: { keepAlive: false },
    component: () => import('../views/ComplaintAdvice.vue'),
  },
  {
    path: '/advises/create',
    name: 'AdviseCreate', //我要回饋/客服問題
    meta: { keepAlive: false },
    component: () => import('../views/AdviseCreate.vue'),
  },
  {
    path: '/advises',
    name: 'AdviseList', //回饋記錄
    meta: { keepAlive: false },
    component: () => import('../views/AdviseList.vue'),
  },
  {
    path: '/replyDetail',
    name: 'RecordDetail', //問題詳情
    meta: { keepAlive: false },
    component: () => import('../views/RecordDetail.vue'),
  },


  // 分割线---------------------------------------------------
  {
    path: '/commonProblem',
    name: 'commonProblem', //常見問題
    meta: { keepAlive: false },
    component: () => import('../views/commonProblem.vue'),
  },
  {
    path: '/aboutUs',
    name: 'aboutUs', //關於我們
    meta: { keepAlive: false },
    component: () => import('../views/document/aboutUs.vue'),
  },
  {
    path: '/userAgreement',
    name: 'userAgreement', //用戶協議
    meta: { keepAlive: false },
    component: () => import('../views/document/UserAgreement.vue'),
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy', //隱私政策
    meta: { keepAlive: false },
    component: () => import('../views/document/PrivacyPolicy.vue'),
  },
  {
    path: '/buyerKnows',
    name: 'buyerKnows', //買家需知
    meta: { keepAlive: false },
    component: () => import('../views/document/BuyersKnows.vue'),
  },
  {
    path: '/payResult',
    name: 'payResult', // 充值成功提示页
    meta: { keepAlive: false },
    component: () => import('../views/payResult.vue'),
  },
  {
    path: '/invitePoster',
    name: 'invitePoster', //元氣邀請碼
    meta: { keepAlive: false },
    component: () => import('../views/invitePoster.vue'),
  },
  {
    path: '/couponGroup',
    name: 'couponGroup', //領券中心
    meta: { keepAlive: false },
    component: () => import('../views/coupon/couponGroup.vue'),
  },

  {
    path: '/authoRization',
    name: 'authoRization', //假一賠十
    meta: { keepAlive: false },
    component: () => import('../views/authoRization.vue'),
  },
  {
    path: '/limitActivity',
    name: 'limitActivity', //限時特惠
    meta: { keepAlive: false },
    component: () => import('../views/activityViews/limitActivity.vue'),
  },
  {
    path: '/questionnaireSurvey',
    name: 'questionnaireSurvey', //有獎問卷
    meta: { keepAlive: false },
    component: () => import('../views/questionnaireSurvey.vue'),
  },
  {
    path: '/questionnaireRules',
    name: 'questionnaireRules',
    meta: { keepAlive: false },
    component: () => import('../views/questionnaireRules.vue'),
  },
  {
    path: '/SignIn',
    name: 'SignIn', //簽到頁
    meta: { keepAlive: false },
    component: () => import('../views/signIn/SignIn.vue'),
  },
  {
    path: '/SignInRules',
    name: 'SignInRules', //簽到頁規則
    meta: { keepAlive: false },
    component: () => import('../views/signIn/SignInRules.vue'),
  },
  {
    path: '/SendCoin',
    name: 'SendCoin', //開盒送幣
    meta: { keepAlive: false },
    component: () => import('../views/SendCoin/SendCoin.vue'),
  },
  {
    path: '/SendCoinRules',
    name: 'SendCoinRules', //開盒送幣規則頁
    meta: { keepAlive: false },
    component: () => import('../views/SendCoin/SendCoinRules.vue'),
  },
  {
    path: '/SendCoinReward',
    name: 'SendCoinReward', //我的獎勵
    meta: { keepAlive: false },
    component: () => import('../views/SendCoin/SendCoinReward.vue'),
  },

  {
    path: '/InviteRewards',
    name: 'InviteRewards', //邀請有獎
    meta: { keepAlive: false },
    component: () => import('../views/invite/InviteRewards.vue'),
  },
  {
    path: '/inviteDetail',
    name: 'inviteDetail', //邀請有禮
    meta: { keepAlive: false },
    component: () => import('../views/invite/inviteDetail.vue'),
  },
  {
    path: '/festivaIActivity',
    name: 'festivaIActivity', //月末限定優惠
    meta: { keepAlive: false },
    component: () => import('../views/activityViews/festivaIActivity.vue'),
  },
  {
    path: '/midAutumnFestival',
    name: 'midAutumnFestival', //FUN價慶中秋
    meta: { keepAlive: false },
    component: () => import('../views/activityViews/midAutumnFestival.vue'),
  },
  {
    path: '/upGradeRules',
    name: 'upGradeRules', //獎品升級规则
    meta: { keepAlive: false },
    component: () => import('../views/upGradeRules.vue'),
  },
  {
    path: '/bargainActivity',
    name: 'bargainActivity', //助力殺價规则
    meta: { keepAlive: false },
    component: () => import('../views/bargain/bargainActivity.vue'),
  },
  {
    path: '/bargainRules',
    name: 'bargainRules', //助力殺價规则
    meta: { keepAlive: false },
    component: () => import('../views/bargain/bargainRules.vue'),
  },
  {
    path: '/bargainShare',
    name: 'bargainShare', //助力殺價分享
    meta: { keepAlive: false },
    component: () => import('../views/bargain/bargainShare.vue'),
  },
  {
    path: '/bargainAssist',
    name: 'bargainAssist', //助力好友殺價
    meta: { keepAlive: false },
    component: () => import('../views/bargain/bargainAssist.vue'),
  },
  {
    path: '/bargainRecords',
    name: 'bargainRecords',
    meta: { keepAlive: false },
    component: () => import('../views/bargain/bargainRecords.vue'),
  },
  {
    path: '/accountDeletion',
    name: 'accountDeletion', // 账号管理-消除账号
    meta: { keepAlive: false },
    component: () => import('@/views/accountDeletion.vue'),
  },
  {
    path: '/worldCup',
    name: 'worldCup', //世界杯
    meta: { keepAlive: false },
    component: () => import('../views/activityViews/worldCup/worldCup.vue'),
  },
  {
    path: '/worldCupRules',
    name: 'worldCupRules', //世界杯规则
    meta: { keepAlive: false },
    component: () => import('../views/activityViews/worldCup/worldCupRules.vue'),
  },
  {
    path: '/doubleEgg',
    name: 'doubleEgg', //双蛋
    meta: { keepAlive: false },
    component: () => import('../views/activityViews/doubleEgg/doubleEgg.vue'),
  },
  {
    path: '/doubleEggRules',
    name: 'doubleEggRules', //双蛋规则
    meta: { keepAlive: false },
    component: () => import('../views/activityViews/doubleEgg/doubleEggRules.vue'),
  },
  {
    path: '/luckyDraw',
    name: 'luckyDraw', // 九宫格抽奖
    meta: { keepAlive: false },
    component: () => import('../views/activityViews/luckyDraw/luckyDraw.vue'),
  },
  {
    path: '/luckyDrawRules',
    name: 'luckyDrawRules', //九宫格抽奖规则
    meta: { keepAlive: false },
    component: () => import('../views/activityViews/luckyDraw/luckyDrawRules.vue'),
  },
  {
    path: '/mustGetRules',
    name: 'mustGetRules',
    meta: { keepAlive: false },
    component: () => import('@/views/mustGetRules.vue'),
  },
  {
    path: '/RepostPoster',
    name: 'RepostPoster', //新人首日特權
    meta: { keepAlive: false },
    component: () => import('../views/RepostPoster.vue'),
  }
];

const router = new VueRouter({
  mode: 'history',
  // base: process.env.VUE_APP_BASE_API,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

export default router;
