// th.js
// 系统公共语言
const system = {
  systemName: 'กล่องตาบอดพลัง',
}

// 登录模块
const login = {
  tab: 'เข้าสู่ระบบ',
  tab2: 'ลงทะเบียน',
  account: 'บัญชี',
  password: '密码',
  code: 'รหัสผ่าน',
  accountPl: 'กรุณากรอกหมายเลขโทรศัพท์',
  passwordPl: 'กรุณากรอกรหัสผ่าน',
  passwordPl2: 'กรุณากรอกรหัสผ่านอีกครั้ง',
  codePl: 'กรุณากรอกรหัสยืนยัน',
  sendCode: 'ส่งรหัสยืนยัน',
  resend: 'ส่งซ้ำ',
  forgetPassword: 'ลืมรหัสผ่านเหรอ?',
  login: 'เข้าสู่ระบบ',
  loging: 'เข้าสู่ระบบ...',
  register: 'ลงทะเบียน',
  reset: 'รีเซ็ตรหัสผ่าน',
  resetHint: 'กรุณากรอกหมายเลขโทรศัพท์มือถือหรืออีเมลของคุณเพื่อรับรหัสยืนยัน และกรอกรหัสยืนยันและรหัสผ่านใหม่',
}

// 首页模块
const home = {
  title: '泰國元氣盲盒',
}

export default {
  login: login,
  system: system,
  home: home
}
