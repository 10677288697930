import store from '../store/index'

// Cookie工具类，用于存储和获取cookie
const TOKEN_KEY = 'boxLucky_token'
const USET_KEY = 'user_details'
const NODE_KEY = 'node_Id'
const SESSION_KEY = 'session_id'
const STORE_CODE = 'store_code'   // 门店编号
const EMPLOYEE_CODE = 'employee_code'   // 员工编号
const LOGIN_ACCOUNT = 'login_account'//记录账号密码
const SAVE_OPENID = 'save_openid'   // 保存openid
const STORE_EPLOYEEID = 'store_eployeeid' // 门店员工id
const STORE_EPLOYEEHEADIMGURL = 'store_eployee_headImg' // 门店员工id
const STORE_STOREINFO = 'store_storeinfo' // 门店信息
const WRITEOFF_POWER = 'writeOff_power' // 核销权限
const GIVELINK_CODE = 'giveLinkCode'
// 保存token
export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token)
}
// 获取token
export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY)
}
// 删除token
export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY)
  // store.commit("token", "");
}
// 保存用户信息
export const setUserDetail = (detail) => {
  localStorage.setItem(USET_KEY, JSON.stringify(detail))
}
// 获取用户信息
export const getUserDetail = () => {
  return JSON.parse(localStorage.getItem(USET_KEY))
}
// 删除用户信息
export const removeUserDetail = () => {
  // store.commit("userDetail", {});
  localStorage.removeItem(USET_KEY)
}
// 保存nodeId
export const setNodeId = (value) => {
  localStorage.setItem(NODE_KEY, value)
}
// 获取nodeId信息
export const getNodeId = () => {
  return localStorage.getItem(NODE_KEY)
}
// 删除nodeId信息
export const removeNodeId = () => {
  localStorage.removeItem(NODE_KEY)
  store.commit("setNodeId", "");
}
// 保存sessionId
export const setSessionId = (value) => {
  localStorage.setItem(SESSION_KEY, value)
}
// 获取sessionId信息
export const getSessionId = () => {
  return localStorage.getItem(SESSION_KEY)
}
// 删除sessionId信息
export const removeSessionId = () => {
  localStorage.removeItem(SESSION_KEY)
  store.commit("setSessionId", "");
}
// 保存storeCode
export const setStoreCode = (value) => {
  localStorage.setItem(STORE_CODE, value)
  store.commit("setStoreCode", value);
}
// 获取storeCode信息
export const getStoreCode = () => {
  return localStorage.getItem(STORE_CODE)
}
// 删除storeCode信息
export const removeStoreCode = () => {
  localStorage.removeItem(STORE_CODE)
  store.commit("setStoreCode", "");
}
// 保存employeeCode
export const setEmployeeCode = (value) => {
  localStorage.setItem(EMPLOYEE_CODE, value)
  store.commit("setEmployeeCode", value);
}
// 获取employeeCode信息
export const getEmployeeCode = () => {
  return localStorage.getItem(EMPLOYEE_CODE)
}
// 删除employeeCode信息
export const removeEmployeeCode = () => {
  localStorage.removeItem(EMPLOYEE_CODE)
  store.commit("setEmployeeCode", "");
}
// 保存登录-账号/密码
export const setLoginAccount = (obj) => {
  localStorage.setItem(LOGIN_ACCOUNT, JSON.stringify(obj))
}
// 获取登录-账号/密码
export const getLoginAccount = () => {
  return localStorage.getItem(LOGIN_ACCOUNT)
}
// 删除登录-账号密码
export const removeLoginAccount = () => {
  localStorage.removeItem(LOGIN_ACCOUNT)
}
// 存储openid
export const setOpenid = ((openid) => {
  localStorage.setItem(SAVE_OPENID, openid)
})
// 获取openid
export const getOpenid = (() => {
  return localStorage.getItem(SAVE_OPENID)
})
// 移除openid
export const removeOpenid = (() => {
  localStorage.removeItem(SAVE_OPENID)
})
// 存储员工id
export const setEployeeid = (eployeeid => {
  localStorage.setItem(STORE_EPLOYEEID, eployeeid ? eployeeid : '')
})
// 获取员工id
export const getEployeeid = (() => {
  return localStorage.getItem(STORE_EPLOYEEID)
})
// 移除员工头像
export const removeEployeeid = (() => {
  localStorage.removeItem(STORE_EPLOYEEID)
})
// 存储员工头像
export const setEployeeHeadImgUrl = (headImgUrl => {
  localStorage.setItem(STORE_EPLOYEEHEADIMGURL, headImgUrl)
})
// 获取员工头像
export const getEployeeHeadImgUrl = (() => {
  return localStorage.getItem(STORE_EPLOYEEHEADIMGURL)
})
// 存储员工头像
export const removeEployeeHeadImgUrl = (() => {
  localStorage.removeItem(STORE_EPLOYEEHEADIMGURL)
})
// 存储店铺信息
export const setStoreInfo = (storeInfo => {
  localStorage.setItem(STORE_STOREINFO, storeInfo)
})
// 获取店铺信息
export const getStoreInfo = (() => {
  return localStorage.getItem(STORE_STOREINFO)
})
// 存储店铺信息
export const removeStoreInfo = (() => {
  localStorage.removeItem(STORE_STOREINFO)
})
// 存储员工id
export const setWriteOffPower = (value => {
  localStorage.setItem(WRITEOFF_POWER, value)
})
// 获取员工id
export const getWriteOffPower = (() => {
  return localStorage.getItem(WRITEOFF_POWER)
})
// 存储员工id
export const removeWriteOffPower = (() => {
  localStorage.removeItem(WRITEOFF_POWER)
})
// 保存share_url
export const setAddr = (value) => {
  localStorage.setItem("share_url", value)
}
// 获取share_url信息
export const getAddr = () => {
  return localStorage.getItem("share_url")
}
// 存储赠送code
export const setGiveLinkCode = ((value)=>{
  localStorage.setItem('GIVELINK_CODE',value)
})
// 获取赠送code
export const getGiveLinkCode = (()=>{
 return localStorage.getItem('GIVELINK_CODE')
})
// 移除赠送code
export const removeGiveLinkCode = (()=>{
  localStorage.removeItem('GIVELINK_CODE')
})
