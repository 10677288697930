import axios from 'axios'
import store from '../store/index'
import router from '../router';
import { getToken, removeToken, removeUserDetail, getStoreCode, getEmployeeCode, removeNodeId, removeSessionId } from '@/utils/localStorage'

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000,
})

request.interceptors.request.use(
  (config) => {
    let token
    if (getToken()) {
      token = getToken()
    }
    // else {
    //   token = 'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl9leHBpcmVfdGltZSI6MTY5Nzg1NTE2NTkyNywibG9naW5fdXNlcl9rZXkiOiJhcHA6MTk0In0.SBN26J-IkDYBP8EB12hw6AK4zZL1-rW37EREO9lhDgp0s3No4-B7rnQqOmmj49j_Vdw_vBmgpSkK556tt8-hKQ'
    // }
    let systemName = "IOS";
    let u = navigator.userAgent;
    let storeMsg = getStoreCode();
    if (getEmployeeCode()) {
      storeMsg += '_' + getEmployeeCode();
    }
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否是 iOS终端
    if (!isIOS) {
      systemName = "Android";
    }
    let hostname = window.location.hostname
    config.headers['domain'] = hostname;
    config.headers['System-Name'] = systemName;
    config.headers['Store-Msg'] = storeMsg;
    config.headers.Authorization = `Bearer ${token}`
    return config
  },
  (error) => Promise.reject(error),
)

request.interceptors.response.use(
  response => {
    // 状态码
    const code = response.data.code;
    // 获取错误信息
    const msg = response.data.msg;
    //分销页面
    const whiteList = store.state.whiteList;
    if (code === 401) {
      removeToken();
      removeUserDetail();
      removeNodeId();
      removeSessionId();
      store.state.token = '';
      store.state.nodeId = '';
      store.state.sessionId = '';
      var path = store.state.lastTimePath || window.location.pathname;
      // 判断是否为分销裂变路径
      if (whiteList.indexOf(path) === -1) {
        // router.push('/');
      }
      if (window.location.href.indexOf('writeOff') !== -1) {
        router.replace({
          path: '/officialAccountsLogin'
        })
      }else if(window.location.href.indexOf('summaryDetails') !== -1){
        router.replace({
          path: '/officialAccountsLogin'
        })
      }
      return Promise.reject('無效的會話，或者會話已過期，請重新登錄。')
    } else {
      return response.data
    }
  },
  error => Promise.reject(error),
)

export default request
