// zhTW.js
// 系统公共语言
const system = {
  systemName: '泰国元气盲盒',
}

// 登录模块
const login = {
  tab: '登入',
  tab2: '註冊',
  account: '賬號',
  password: '密碼',
  code: '驗證碼',
  accountPl: '請輸入行動號碼',
  passwordPl: '請輸入密碼',
  passwordPl2: '請再次輸入密碼',
  codePl: '請輸入驗證碼',
  sendCode: '發送驗證碼',
  resend: '重新發送',
  forgetPassword: '忘記登入密碼？',
  login: '登入',
  loging: '登 入 中...',
  register: '註冊',
  reset: '重置密碼',
  resetHint: '請輸入手機號或郵箱來獲取驗證碼，並填寫驗證碼以及新密碼',
}

// 首页模块
const home = {
  title: '泰國元氣盲盒',
}

export default {
  login: login,
  system: system,
  home: home
}
