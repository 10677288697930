import { addlog } from "@/api/public";
import { getAssets } from "@/api/user";
import { getJsapiSignature } from "@/api/public";
import { setNodeId, setSessionId, getStoreCode, getEmployeeCode } from "@/utils/localStorage";
export default {
  data() {
    return {
      link: "http://h5.local.timelab.cn/",
      token: "",
      deviceId: "",
      coinTotalNum: 0, // 用户幸运币资产
    }
  },

  methods: {
    // 获取token
    getToken() {
      if (window.android) {
        const userInfo = JSON.parse(window.android.getUserInfo());
        this.token = userInfo.token;
      } else if (this.$route.query.userToken) {
        this.token = this.$route.query.userToken;
      }
      this.$store.commit("setUserToken", this.token);
    },

    refreshToken() {
      this.$bridge.registerHandler("refreshPage", (data) => {
        this.token = data.userToken;
        this.$store.commit("setUserToken", this.token);
      });
    },

    // 查询用户资产
    getAssets() {
      getAssets().then(res => {
        if (res.code == 200) {
          this.coinTotalNum = res.data.amount;
        }
      })
    },

    // 用户操作记录
    async setUserLog(cid, data) {
      await addlog(cid, data).then(res => {
        if (res.code == 200) {
          this.$store.commit("setNodeId", res.data.nodeId);
          setNodeId(res.data.nodeId);
          this.$store.commit("setSessionId", res.data.sessionId);
          setSessionId(res.data.sessionId);
          this.userLog.nodeId = res.data.nodeId;
          this.userLog.sessionId = res.data.sessionId;
        }
      })
    },

    // 获取deviceId
    getDeviceId() {
      if (window.android) {
        const userInfo = JSON.parse(window.android.getUserInfo());
        this.deviceId = userInfo.deviceid;
      } else if (this.$route.query.deviceId) {
        this.deviceId = this.$route.query.deviceId;
      }
    },

    // 查看盲盒详情页
    handleBlindBoxDetail(id) {
      if (this.token) {
        if (id > 0) {
          if (window.android) {
            window.android.goBlindBox(id);
          } else {
            this.$bridge.callHandler(
              "goRouter",
              `{"tag":"router","IOS":{"path": "/blindbox","hasParam":true,"id":"${id}"},"expireTimeStamp":0}`
            );
          }
        }
      } else {
        this.$Toast.fail("กรุณาเข้าสู่ระบบก่อน~");
        setTimeout(() => { this.handleGoLogin() }, 500);
      }
    },

    // 跳转登录
    handleGoLogin() {
      if (window.android) {
        window.android.goLogin();
      } else {
        this.$bridge.callHandler("goLogin");
      }
    },

    // 前往首页
    handleGoHome() {
      if (window.android) {
        window.android.goHome();
      } else {
        this.$bridge.callHandler("goHome");
      }
    },

    // 前往签到页面
    handleGoSingIn() {
      // android跳首页，ios跳签到页
      if (window.android) {
        window.android.goHome();
      } else {
        if (this.token) {
          this.$router.replace("/SignIn");
        } else {
          this.handleGoLogin();
        }
      }
    },

    // 跳转充值页
    toVoucher() {
      if (this.token) {
        if (window.android) {
          window.android.goRouter(
            '{"tag":"router","Android":{"path": "/recharge","hasParam": ture,"finishFlag": false}}'
          );
        } else {
          this.$bridge.callHandler(
            "goRouter",
            `{"tag":"router","IOS":{"path": "/recharge","hasParam":false}}`
          );
        }
      } else {
        this.$Toast.fail("กรุณาเข้าสู่ระบบก่อน~");
        setTimeout(() => {
          this.handleGoLogin();
        }, 500);
      }
    },

    // 跳转我的优惠券/功能卡页面
    handleJump(address) {
      if (address === "/myCoupon") {
        if (window.android) {
          window.android.goLucky();
        } else {
          this.$bridge.callHandler(
            "goRouter",
            `{"tag":"router","IOS":{"path": "/myCoupon","hasParam":false },"expireTimeStamp":0}`
          );
        }
      } else if (address == "/FunctionCard") {
        if (window.android) {
          window.android.goRouter(
            '{"tag":"link","Android":{"url": "' +
            this.h5Link +
            address +
            '","hasParam": ture,"finishFlag": false}}'
          );
        } else {
          this.$bridge.callHandler(
            "goRouter",
            '{"tag":"link","IOS":{"url": "' + this.h5Link + address + '"}}'
          );
        }
      }
    },

    // 跳转收纳盒页面
    toDepot() {
      if (window.android) {
        window.android.goRouter(
          '{"tag":"router","Android":{"path": "/depot","hasParam": true},"expireTimeStamp":0}'
        );
      } else {
        this.$bridge.callHandler(
          "goRouter",
          `{"tag":"router","IOS":{"path": "/depot","hasParam":true},"expireTimeStamp":0}`
        );
      }
    },

    // 分享微信朋友、朋友圈
    shareWx(shareText = '分享一个有趣的东西给你', shareImg = 'https://h5.test.timelab.cn/logo.ico', shareDesc = "") {
      let that = this;
      let shareLink = window.location.origin + `/?storeCode=${getStoreCode()}`;
      if (getEmployeeCode()) {
        shareLink += `&employeeCode=${getEmployeeCode()}`;
      }
      let redirectUrl = window.location.href;
      getJsapiSignature(encodeURIComponent(redirectUrl)).then(res => {
        if (res.code == 200) {
          this.$wx.config({
            // debug: true,
            appId: res.data.appId,  // appID 公众号的唯一标识
            timestamp: res.data.timestamp, // 签名的时间戳
            nonceStr: res.data.nonceStr, //  签名的随机串
            signature: res.data.signature, // 签名
            jsApiList: [
              'updateAppMessageShareData', // 分享到朋友
              'updateTimelineShareData', // 分享到朋友圈
            ]
          });
          this.$wx.error(res => {
            console.log("出错了：" + res.errMsg);
          });
          that.$wx.ready(function () { //需在用户可能点击分享按钮前就先调用
            // 分享朋友
            that.$wx.updateAppMessageShareData({
              title: shareText, // 分享标题
              desc: shareDesc, // 分享描述
              link: shareLink, // 分享链接 该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: shareImg, // 分享图标
              success: function (res) {
                console.log("分享朋友 success:", res);
              },
              fail: function (error) {
                console.log('分享朋友 error:', error);
              }
            })

            // 分享朋友圈
            that.$wx.updateTimelineShareData({
              title: shareText, // 分享标题
              desc: shareDesc, // 分享描述
              link: shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: shareImg, // 分享图标
              success: function () {
                console.log("分享朋友圈 success:", res);
              },
              fail: function (error) {
                console.log('分享朋友圈 error:', error);
              }
            })
          });
        } else {
          this.$Toast.fail(res.msg);
        }
      })
    },

  }
}
