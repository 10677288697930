<template>
  <div>
    <van-popup v-model="loginShow" round position="bottom">
      <div class="topTool">
        <div class="titleName">
          <img src="@/assets/public-img/logo.png" class="logo" />
          宝盒时间
        </div>
        <div class="closeBtn" @click="loginShow = false">
          <img src="@/assets/public-img/icon_close_black.png" />
        </div>
      </div>
      <div class="wxLoginBox" @click="wechatLogin()">
        <img src="@/assets/public-img/wxlogo.png" class="wxLogoImg" />
        <div class="logoText">点击微信授权登录</div>
      </div>

      <div class="agreement">
        <van-checkbox v-model="checked" icon-size="13px">
          <div class="agreementText">
            我已阅读并同意<span @click.stop="goService()">《宝盒时间用户协议》</span><span @click.stop="goPrivacy()">《用户隐私政策》</span>
          </div>
        </van-checkbox>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from "vue";
import { Popup, Checkbox } from "vant";
import { getWXRegister, getAdminWXRegister } from "@/api/login";
import { userDetail, adminUserDetail } from "@/api/login";
import { getToken, setToken, setUserDetail, setEployeeHeadImgUrl } from "@/utils/localStorage";
Vue.use(Popup);
Vue.use(Checkbox);
export default {
  name: "wxLoginPopup",
  props: {
    code: {
      type: String,
    },
    loginType: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    code: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.getWXRegister();
        }
      },
    },
  },

  data() {
    return {
      loginShow: false,
      checked: false,
      appId: process.env.VUE_APP_WXAPPID,
    };
  },
  methods: {
    // 打开登录弹窗
    openLoginFn() {
      this.loginShow = true;
    },

    // 关闭登录弹窗
    closeLoginFn() {
      this.loginShow = false;
    },

    // 跳转服务协议
    goService() {
      this.$router.push("/userAgreement");
    },

    // 跳转隐私政策
    goPrivacy() {
      this.$router.push("/privacyPolicy");
    },

    // 微信登录
    wechatLogin() {
      if (this.checked) {
        let redirectUrl = window.location.href;
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      } else {
        this.$toast("请先勾选全文阅读《宝盒时间用户协议》和《用户隐私政策》");
      }
    },

    // 访问后端微信注册接口
    async getWXRegister() {
      this.$Toast.loading({
        forbidClick: true,
        duration: 60000,
      });
      let res;
      if (this.loginType) {
        res = await getWXRegister(this.code);
      } else {
        res = await getAdminWXRegister(this.code);
      }

      try {
        if (res.code == 200) {
          await setToken(res.data.token);
          this.$store.state.token = await getToken();
          let res2;
          if (this.loginType) {
            res2 = await userDetail();
          } else {
            res2 = await adminUserDetail(res.data.openid);
            setEployeeHeadImgUrl(res.data.headImgUrl)
          }
          setUserDetail(res2.data);
          this.$Toast.clear();
          this.loginShow = false;
          // 通知父组件登录完成
          this.$emit("loginFinish", res.data.token, res.data.openid);
        } else {
          this.$Toast.clear();
          // this.$Toast.fail(res.msg);
          console.dir(res.msg);
        }
      } catch (error) {
        this.$Toast.clear();
        // this.$toast.fail("登录失败");
        console.dir(error);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.topTool {
  width: calc(100% - 32px);
  padding: 16px;
  .disflex(center, space-between);

  .titleName {
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    .disflex(center, center);

    .logo {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      margin-right: 7px;
    }
  }

  .closeBtn {
    width: 24px;
    height: 24px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.wxLoginBox {
  width: 100%;
  padding-top: 33px;
  padding-bottom: 30px;
  .disflex(center, center);
  flex-direction: column;

  .wxLogoImg {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 15px;
  }

  .logoText {
    font-size: 13px;
    color: #252525;
  }
}

.agreement {
  width: calc(100% - 32px);
  padding: 0 16px;
  padding-bottom: 45px;
  display: flex;
  align-items: flex-start;

  .agreementText {
    font-size: 12px;
    color: #252525;

    span {
      color: #1a85de;
    }
  }
}
</style>
