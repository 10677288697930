import request from '@/utils/request'

//手機號或者郵箱賬號密碼登錄
export function toLogin(data, deviceId) {
  return request({
    method: 'post',
    headers: { 'Device-Id': deviceId },
    url: '/api/smsOrEmailLogin/v1.0.0',
    data,
  })
}

//获取验证码
export function getRegisterCode(data) {
  return request({
    method: 'post',
    url: '/api/getRegisterCode/v1.0.0',
    data,
  })
}

// 注册
export function register(data) {
  return request({
    method: 'post',
    url: '/api/register/v1.0.0',
    data,
  })
}

// 获取用户详情信息
export function userDetail() {
  return request({
    url: '/api/user/detail/v1.0.0',
    method: 'get'
  })
}

// 忘记密码 获取手机-邮箱验证码
export function getForgetPassWordCode(data) {
  return request({
    method: 'post',
    url: '/api/getForgetPassWordCode/v1.0.0',
    data,
  })
}

// 忘记密码
export function forgetPassword(data) {
  return request({
    method: 'post',
    url: '/api/user/forgetPassword/v1.0.0',
    data,
  })
}

// 获取微信用户信息
export function getWXRegister(code) {
  return request({
    method: 'get',
    url: '/api/getWXRegister?code=' + code,
  })
}
// 获取后台微信用户信息
export function getAdminWXRegister(code){
  return request({
    method:'get',
    url:`${process.env.VUE_APP_OFFICIAL_API}/admin/agentStore/getWXRegister`,
    params:{
      code
    }
  })
}
// 获取后台用户详情信息
export function adminUserDetail(openId) {
  return request({
    url:`${process.env.VUE_APP_OFFICIAL_API}/admin/agentStore/baseInfo`,
    method: 'get',
    params:{
      openId
    }
  })
}
